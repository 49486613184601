<template>
  <div class="index" v-loading="pageLoading">
    <div class="content">
      <crumbs-custom :arr="TabBar" />
      <div class="cont">
        <div class="p-20 conts">
          <div class="flex-row show-data">
            <div class="thumb txt-center">
              <img class="" :src="dataCont.basic_logo" alt="" />
            </div>
            <div class="flex-cell flex-col">
              <div class="title-name font-weight">
                {{ dataCont.basic_com_name }}
              </div>
              <div class="types">
                <span>{{ typeName }}服务</span>
              </div>
              <div class="tags">
                <span
                  v-for="(item, index) in dataCont.service_type"
                  :key="index"
                  >#{{ item }}</span
                >
              </div>
            </div>
            <div class="flex-col">
              <div class="cnt flex-row txt-center">
                <div class="flex-row" style="margin-right: 40px">
                  <img src="@/assets/icon/num1.png" alt="">
                  <div class="flex-col">
                    <div class="font-weight num">{{dataCont.callCnt || 0}}</div>
                    <div class="">电话查询量</div>  
                  </div>
                </div>
                <div class="flex-row">
                  <img src="@/assets/icon/num2.png" alt="">
                  <div class="flex-col">
                    <div class="font-weight num">{{dataCont.cateCnt || 0}}</div>
                    <div class="">分类查询量</div>  
                  </div>
                </div>
              </div>
              <div class="flex-row">
                <div class=""></div>
                <div class="btns cursor-pointer" @click="$router.push({ path: '/searchShow/' + dataCont.id })">信用查询</div>
              </div>
            </div>
          </div>
          <el-descriptions class="margin-top" title="" :column="2" border>
            <el-descriptions-item
              label="法人代表"
              label-class-name="custor-label"
              >{{ dataCont.basic_ceo_name }}</el-descriptions-item
            >
            <el-descriptions-item
              label="企业联系人"
              label-class-name="custor-label"
              >{{ dataCont.contacts_name }}</el-descriptions-item
            >
            <el-descriptions-item
              label="注册资金"
              label-class-name="custor-label"
              >{{ dataCont.basic_reg_amount }}</el-descriptions-item
            >
            <el-descriptions-item
              label="服务热线"
              label-class-name="custor-label"
              >{{ dataCont.contacts_mobile }}</el-descriptions-item
            >
            <el-descriptions-item
              label="成立时间"
              label-class-name="custor-label"
              >{{ dataCont.establish_date }}</el-descriptions-item
            >
            <el-descriptions-item
              label="单位地址"
              label-class-name="custor-label"
              >{{ dataCont.company_addr }}</el-descriptions-item
            >
            <el-descriptions-item
              label="企业简介"
              label-class-name="custor-label"
              >{{ dataCont.company_bref }}</el-descriptions-item
            >
          </el-descriptions>
          <el-descriptions
            class="margin-top other-table"
            title=""
            :column="1"
            border
          >
            <el-descriptions-item
              label="经营范围"
              label-class-name="custor-label"
              >{{ dataCont.basic_serv_area }}</el-descriptions-item
            >
          </el-descriptions>
          <div class="font-weight m-t-30">企业链接</div>
          <div class="flex-row link-div">
            <div class="link-cell web-link">
              <div class="flex-row">
                <img class="icons" src="@/assets/icon/web.png" alt="" />
                <div class="">找服务</div>
                <div class="txt-center">
                  <span class="img-divs" v-if="dataCont.special">
                    <div class="code-div m-r-10 m-l-10" >
                      <img
                        class="img-code"
                        :src="dataCont.android_img"
                        v-if="dataCont.android_img"
                        alt=""
                      />
                      <div class="txt-center">安卓</div>
                    </div>
                    <div class="code-div m-l-10">
                      <img
                        class="img-code"
                        :src="dataCont.ios_img"
                        v-if="dataCont.ios_img"
                        alt=""
                      />
                      <div class="txt-center">IOS</div>
                    </div>
                  </span>
                  <span
                    v-else
                    class="cursor-pointer links m-l-10"
                    @click="doOpenWeb(dataCont.official_link)"
                    >{{dataCont.official_link}}</span
                  >
                </div>
              </div>
            </div>
            <div class="link-cell flex-row mini-link" v-if="dataCont.mini_program_img">
              <img class="icons" src="@/assets/icon/mini.png" alt="" />
              <div class="">小程序</div>
              <img class="img-code m-l-10" :src="dataCont.mini_program_img" alt="" />
            </div>
            <div class="link-cell flex-row h5-link" v-if="dataCont.official_account_img">
              <img class="icons" src="@/assets/icon/h5.png" alt="" />
              <div class="">公众号</div>
              <img class="img-code m-l-10" :src="dataCont.official_account_img" alt="" />
            </div>
            <div class="flex-cell"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { requestPost, requestGet } from "@/api/home/index";
import { setStore, getStore } from "@/utils/auth";
export default {
  name: "Home",
  props: {},
  data() {
    return {
      pageLoading: false,
      dataCont: {
        service_type: [],
      },
      typeName: this.$route.params.title,
      TabBar: []
    };
  },
  mounted() {
    l_loadData(this);
    l_setData(this)
  },
  methods: {
    doOpenWeb(url) {
      window.open(url);
    },
  },
};
function l_loadData(pg) {
  pg.pageLoading = true;
  requestGet("api/company/" + pg.$route.params.id, {})
    .then((res) => {
      pg.dataCont = res.data;
      pg.TabBar = [{router: '/serviceType', title: '家政服务类型'}, {router: '/serviceLists/'+pg.$route.params.cid, title: pg.$route.params.title},{title: pg.dataCont.basic_com_name,router: "/serviceShow/" + pg.$route.params.id}]
      pg.pageLoading = false;
    })
    .catch((error) => {
      pg.$message.error(error);
      pg.pageLoading = false;
    });
}

function l_setData(pg){
  requestGet("api/mp/company/catevisitor", {comId: pg.$route.params.id,cateId: pg.$route.params.cid})
    .then((res) => {})
    .catch((error) => {
      pg.$message.error(error);
      pg.pageLoading = false;
    });
	}
</script>
<style lang="scss" scoped>
.content {
  width: 1200px;
  margin: 0 auto;
  .cont {
    background: #fff;
  }
  .conts {
    background: #fff;
  }
  .show-data {
    margin: 46px auto;
    .thumb {
      width: 120px;
      height: 120px;
      margin-right: 15px;
      border-radius: 5px;
      box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.1);
      img {
        width: 90%;
        max-height: 90%;
      }
    }
    .title-name {
      font-size: 24px;
    }
    .cnt{
      font-size: 14px;
      color: #86909C;
      img{
        width: 40px;
        height: 40px;
        margin-right: 10px;
        
      }
      .num{
        font-size: 20px;
        color: #333;
      }
      
    }
    .btns{
        background: #FFEFE8;
        color: #FF7D00;
        font-size: 16px;
        padding: 5px 10px;
        border: 1px solid #FF7D00;
        border-radius: 3px;

      }
    .types {
      font-size: 14px;
      span {
        background: #fde2e2;
        color: #db3e3e;
        padding: 5px 10px;
        border-radius: 5px;
      }
    }
    .tags {
      font-size: 14px;
      span {
        color: #888;
        margin-right: 10px;
      }
    }
  }
  .link-div {
    margin-top: 20px;
    font-size: 16px;
    height: 150px;
    .link-cell {
      margin-right: 100px;
      color: #888;
      .icons {
        width: 25px;
        height: 25px;
        margin-right: 15px;
      }
      span {
        font-size: 16px;
        color: #333;
      }
      .links{
        color: rgb(0, 17, 255);
        text-decoration: underline;
      }
      .img-code {
        width: 100px;
        height: 100px;
      }
      .img-divs {
        .code-div {
          display: inline-block;
        }
      }
    }
  }
  ::v-deep .custor-label {
    background: #fff4f4 !important;
    width: 200px !important;
    color: #333 !important;
  }
  .other-table {
    ::v-deep .el-descriptions-item__cell {
      border-top: 0 !important;
    }
  }
}
</style>
